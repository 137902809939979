.invest-td-2 {
  width: 220px;
  text-align: right;
}

.invest-td-3 {
  width: 220px;
  text-align: right;
}

.invest-td-4 {
  width: 220px;
  text-align: right;
}