#img-lightning-right-top {
  width: 438px;
  height: 239px;
  position: absolute;
  right: 0;
  top: 0;
  animation: ligntningDynamic 5s infinite;
  z-index: -1;
}

@keyframes ligntningDynamic {
	0% {opacity: 0}
  2% {opacity: 0.8}
	4% {opacity: 0}
  6% {opacity: 0.8}
  8% {opacity: 0}
  10% {opacity: 1}
  20% {opacity: 0.9}
  30% {opacity: 0.8}
  40% {opacity: 0.7}
  50% {opacity: 0.6}
  60% {opacity: 0.5}
  70% {opacity: 0.4}
  80% {opacity: 0.3}
  90% {opacity: 0.2}
  100% {opacity: 0.1}
}

#hands-lightning {
  width: 781px;
  height: 500px;
  position: absolute;
  left: 177px;
  bottom: 0;
  z-index: -1;
}
@media all and (max-width: 1080px) {
  #hands-lightning {
    /* display: none; */
    left: 0;
  }
}
@media all and (min-width: 1800px) {
  #hands-lightning {
    left: 200px;
  }
}

#img-hands {
  width: 781px;
  height: 500px;
}

#img-lightning {
  width: 466px;
  height: 350px;
  position: absolute;
  left: 167px;
  bottom: 69px;
}

#bridge-view {
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

#bridge-window {
  width: 620px;
  height: 680px;
  background-image: url(../../assets/bridge-window-bg.svg);
  position: relative;
  /* padding: 35px 40px; */
}

#icon-seting {
  width: 40px;
  height: 40px;
  background-image: url(../../assets/setting-uncheck.svg);
  cursor: pointer;
}

#session-from {
  background: #11262E;
  border-radius: 10px;
  margin-top: 32px;
  overflow: hidden; /*otherwise child's margin-top will influance here*/
  width: 540px;
  padding: 17px 19px;
  box-sizing: border-box;
}

.label-left {
  font-size: 14px;
  font-family: ArialMT;
  color: #FFFFFF;
  line-height: 16px;
}

#label-available {
  font-size: 14px;
  font-family: ArialMT;
  color: #FFFFFF;
  line-height: 16px;
}

#label-available-amount {
  font-size: 16px;
  font-family: Arial-BoldMT, Arial;
  font-weight: normal;
  color: #32FDEF;
  line-height: 18px;
}

#btn-max {
  width: 40px;
  height: 20px;
  /* background: #35F3ED; */
  background: rgba(53, 243, 237, 0.1);
  border-radius: 4px;
  font-size: 12px;
  font-family: ArialMT;
  /* color: #213340; */
  color: #9FA8AB;
  line-height: 14px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

#btn-half {
  width: 40px;
  height: 20px;
  background: rgba(53, 243, 237, 0.1);
  border-radius: 4px;
  font-size: 12px;
  font-family: ArialMT;
  color: #9FA8AB;
  line-height: 14px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

#btn-max[highlight="1"], #btn-half[highlight="1"] {
  background: #35F3ED;
  color: #213340;
}

#session-to {
  background: #11262E;
  border-radius: 10px;
  margin-top: 14px;
  overflow: hidden; /*otherwise child's margin-top will influance here*/
  width: 540px;
  padding: 17px 19px;
  box-sizing: border-box;
}

#session-rate {
  background: #11262E;
  border-radius: 10px;
  margin-top: 14px;
  overflow: hidden; /*otherwise child's margin-top will influance here*/
  width: 540px;
  padding: 17px 19px;
  box-sizing: border-box;
  margin-bottom: 25px;
}

#btn-bridge {
  height: 50px;
  border-radius: 10px;
  background-image: linear-gradient(to right, #32FDEF, #2AF940);
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  font-family: PingFangSC-Semibold, PingFang SC;
  user-select: none
}

#btn-connect-joltify {
  width: 100%;
  height: 50px;
  background: linear-gradient(121deg, #2DB3F8 0%, #38C5E2 12%, #3D4BE4 87%, #8561FE 100%);
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  font-family: PingFangSC-Semibold, PingFang SC;
  user-select: none;
  color: #FFFFFF;
}

#btn-connect-bsc-bridge {
  width: 100%;
  height: 50px;
  background: #F5841F;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  font-family: PingFangSC-Semibold, PingFang SC;
  user-select: none;
  color: #FFFFFF;
}