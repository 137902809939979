.left-menu {
  position: fixed;
  width: 266px;
  height: 100vh;
  background-color: #213340;
  top: 0;
  border-radius: 0 55px 0 0;
}

#social-icons {
  position: absolute;
  bottom: 32px;
  width: 100%;
}

.social-icon {
  width: 25%;
  text-align: center;
}

.img-social {
  width: 30px;
  cursor: pointer;
}

#price-info {
  font-size: 12px;
  color: #177F77;
  width: 100%;
  position: absolute;
  bottom: 84px;
  text-align: center;
}

#btn-wallet {
  /* position: absolute; */
  /* bottom: 188px; */
  width: 100%;
  font-family: PingFangSC-Semibold, PingFang SC;
  user-select: none
}

#btn-connect {
  height: 40px;
  border-radius: 10px;
  width: 100%;
  display: flex;
  align-items: center;
  cursor: pointer;
  height: 40px;
  background: linear-gradient(121deg, #2DB3F8 0%, #38C5E2 12%, #3D4BE4 87%, #8561FE 100%);
  font-size: 16px;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: #FFFFFF;
  line-height: 22px;
}

#btn-connect-bsc {
  height: 40px;
  border-radius: 10px;
  width: 100%;
  display: flex;
  align-items: center;
  /* justify-content: center; */
  cursor: pointer;
  height: 40px;
  /* background: linear-gradient(121deg, #2DB3F8 0%, #38C5E2 12%, #3D4BE4 87%, #8561FE 100%); */
  font-size: 16px;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: #FFFFFF;
  line-height: 22px;

  /* width: 206px; */
  /* height: 40px; */
  background: #F5841F;
  /* border-radius: 10px; */
}

#btn-sign-out {
  height: 40px;
  border-radius: 10px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border: 1px solid #2DCAC1;
}

#balance {
  /* position: absolute; */
  /* bottom: 250px; */
  width: 100%;
  /* width: 206px; */
  height: 60px;
  background: #11262E;
  border-radius: 10px;
}

#wallet-name {
  font-size: 16px;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: #FFFFFF;
  line-height: 22px;
}

#wallet-value {
  font-size: 12px;
  font-family: ArialMT;
  color: #9FA8AB;
  line-height: 14px;
}

.menu-tab {
  position: relative;
  margin-top: 60px;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-left: 55px;
  z-index: 2;
  cursor: pointer;
  user-select:none
}

.menu-title {
  font-size: 14px;
  font-family: Arial-BoldMT, Arial;
  font-weight: normal;
  color: #FFFFFF;
  line-height: 16px;
}

#tab-bg {
  width: 236px;
  height: 170px;
  position: absolute;
  left: 30px;
  z-index: 1;
}