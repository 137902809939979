body {
    margin: 0;
    background-color: #112229;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none !important;
}
input[type="number"]{
  -moz-appearance: textfield;
}

*[disabled] {
  opacity: 0.5;
  pointer-events: none;
}

img {
  user-select: none
}

a {
  text-decoration: none;
}

/* * {
  user-select: none
} */