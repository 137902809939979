.pool-column-line {
  width: 1px;
  height: 58px;
  background: #293C43;
}

.pool-top-item {
  width: 180px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: end;
  height: 58px
}

.pool-top-item-up {
  font-size: 16px;
  font-family: Arial-BoldMT, Arial;
  font-weight: normal;
  color: #FFFFFF;
  line-height: '18px'
}

.pool-top-item-down {
  margin-top: 19px;
  font-size: 12px;
  font-family: ArialMT;
  color: #9FA8AB;
  line-height: 14px
}

.pool-top-item-up-hr3 {
  display: flex;
  align-items: flex-end;
}

.pool-top-item-up-icon {
  width: 20px;
  height: 20px; 
}

.pool-top-item-up-value {
  padding: 0 6px;
}

.pool-top-item-up-uint {
  font-size: 12px;
  font-family: ArialMT;
  color: #9FA8AB;
  line-height: 14px;
  align-self: center;
  padding-top: 4px;
}